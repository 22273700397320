import * as React from 'react'
import { StaticQuery, graphql } from "gatsby"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from 'swiper';

SwiperCore.use([Pagination]);

const TemoignagesSection = () => (
    <StaticQuery
      query={graphql`
        query {
          temoignages: allMdx(
            filter: {
              fileAbsolutePath: { regex: "/temoignages/"},
              frontmatter: { accueil: { eq: true }, actif: { eq: true } }
            },
            sort: {fields: frontmatter___date, order: DESC}
          )
          {
            nodes {
              frontmatter {
                nom
                evaluation
                site_internet
                lien
                accueil
                actif
                date(formatString: "MMMM D, YYYY")
              }
              id
              slug
              body
            }
          }
        }
      `}
      render={data => (
        <section className="testimonials">
          <div className="container">
            <div className="section-title">
              <h2>Témoignages</h2>
              <p>Une équipe expérimentée, compétente et à votre écoute</p>
            </div>
            <Swiper pagination={{"clickable": true}} className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
            {
              data.temoignages.nodes.map((temoignage, index) => (
                <SwiperSlide key={index} className="swiper-slide">
                <div className="testimonial-item">
                  <a href={temoignage.frontmatter.lien} className="text-muted float-end" target="_blank" rel="noreferrer">Sur {temoignage.frontmatter.site_internet}</a>
                  <div className="h5 mb-0">{temoignage.frontmatter.nom}</div>
                  <div className="text-warning">
                    <i className="bi bi-star-fill me-1"></i>
                    <i className="bi bi-star-fill me-1"></i>
                    <i className="bi bi-star-fill me-1"></i>
                    <i className="bi bi-star-fill me-1"></i>
                    <i className="bi bi-star-fill me-1"></i>
                  </div>
                  <div className="mb-2">
                      <MDXRenderer>
                        {temoignage.body}
                      </MDXRenderer>
                  </div>
                </div>
              </SwiperSlide>                  
              ))
            }
            </Swiper>
          </div>
        </section>
      )}
    />
)

export default TemoignagesSection