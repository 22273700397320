import * as React from 'react'

const AboutSection = () => {
  return (
    <section className="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="ratio ratio-16x9">
              <iframe src="https://www.youtube.com/embed/h2XfIfmE7QY?rel=0" title="Max Blank Odin" allowFullScreen></iframe>
            </div>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <h3>Bien choisir son poêle</h3>
            <div className="row">
              <div className="col-md-6">
                <div className="h4">Chauffage principal</div>
                <p>Le poêle à granulés ou pellets pour leur confort d’utilisation, leur autonomie et la régulation de la température. Les poêles à bûches à accumulation ou inertie, appareils lourds et massif qui restituent la chaleur sur des périodes pouvant aller jusqu’à 24 heures.</p>
              </div>
              <div className="col-md-6">
                <div className="h4">Chauffage d’appoint</div>
                <p>Hormis les poêle de masse, les appareils à bûches sont plus utilisés en chauffage d’appoint pour faire du complément de chauffage ou en remplacement d’un ancien appareil. Un appareil de chauffage à bûches atteint ses performance maximal avec des essence de bois dur comme le charme, le chêne, le hêtre, le frêne, l'orme et l'érable, possédant un taux d’humidité inferieur à 20%.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutSection