import * as React from 'react'

const ServicesSection = () => {
  return (
    <section className="services">
      <div className="container">
        <div className="section-title">
          <h2>Services</h2>
          <p>Un accompagnement complet et personnalisé.</p>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box iconbox-blue shadow" data-aos="zoom-in" data-aos-delay="100">
              <div className="h4">Show-room</div>
              <p>Notre show-room de Saint-Romain-la-Motte est le lieu idéal pour discuter de votre projet avec notre technicien.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div className="icon-box iconbox-orange shadow" data-aos="zoom-in" data-aos-delay="200">
              <div className="h4">Etude et conseil</div>
              <p>Conseils et étude de votre dossier, visite technique à domicile.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div className="icon-box iconbox-pink shadow" data-aos="zoom-in" data-aos-delay="300">
              <div className="h4">Installation et Service après vente</div>
              <p>La pose et le suivi de votre installation est effectué par nos équipes de pose, sans sous traitance.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServicesSection