import * as React from 'react'

// Components
import Layout from '../components/layout/Layout'
import HeroSection from '../components/section/Hero'
import MarquesSection from '../components/section/Marques'
import AboutSection from '../components/section/About'
import CountsSection from '../components/section/Counts'
import ServicesSection from '../components/section/Services'
import QualificationsSection from '../components/section/Qualifications'
import CTASection from '../components/section/CTA'
import TemoignagesSection from '../components/section/Temoignages'
import PartenairesSection from '../components/section/Partenaires'

const IndexPage = () => {
  return (
    <Layout pageTitle="Accueil" headerClassPosition="fixed-top" headerClassBackground="transparent">
      <HeroSection/>
      <MarquesSection/>
      <AboutSection/>
      <CountsSection/>
      <ServicesSection/>
      <QualificationsSection/>
      <CTASection/>
      <TemoignagesSection/>
      <PartenairesSection/>
    </Layout>
  )
}

export default IndexPage
