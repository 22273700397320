import * as React from 'react'
import { StaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay, EffectFade} from 'swiper';

SwiperCore.use([Pagination, Autoplay, EffectFade]);

const HeroSection = () => (
  <StaticQuery
    query={graphql`
      query {
        produits: allMdx(
          filter: {
            fileAbsolutePath: {regex: "/produits/"}
            frontmatter: { accueil_hero: { eq: true }, actif: { eq: true } }
          }
          sort: {fields: frontmatter___date, order: ASC}
          limit: 6
        ) {
          nodes {
            frontmatter {
              hero_image {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
              hero_image_alt
              hero_image_credit_link
              hero_image_credit_text
              accueil
              actif
              date(formatString: "MMMM D, YYYY")
            }
          }
        }
      }
    `}
    render={data => (
      <section className="hero d-flex align-items-center justify-content-center">
        <Swiper
          modules={[Navigation, Pagination, Autoplay, EffectFade]}
          navigation
          pagination={{"clickable": true}}
          autoplay={{ delay: 5000}}
          effect="fade"
          fadeEffect= {{crossFade: true }}
          spaceBetween={30}
          className="swiper"
        >
          {
            data.produits.nodes.map((produit,index) => (
              <SwiperSlide key={index} className="swiper-slide">
                <GatsbyImage
                  image={getImage(produit.frontmatter.hero_image)}
                  className="swiper-slide"
                  alt={produit.frontmatter.hero_image_credit_text}
                />
              </SwiperSlide>                  
            ))
          }
        </Swiper>
        <div className="container position-relative">
          <h1>L’Atrier Roannais</h1>
          <h2>Vente, installation et suivi d'appareils de chauffage depuis 2010 dans la Loire.</h2>
          <Link to="/contact/" className="btn-get-started scrollto">
              Contactez Nous
          </Link>
        </div>
      </section>
    )}
  />
)

export default HeroSection