import * as React from 'react'
import { Link } from "gatsby"

import rgeQualibois from "../../images/rge-qualibois.jpg"

const QualificationsSection = () => {
  return (
    <section className="qualifications">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-4 offset-lg-1">
            <div className="img-fluid">
              <Link to="/">
                  <img src={rgeQualibois} alt="RGE Qualibois - L'Atrier Roannais" width="100%" height="" className="img-fluid"></img>
              </Link>  
            </div>
          </div>
          <div className="col-md-6 col-lg-6 pt-4 pt-lg-0 justify-content-center align-self-center">
            <h3>Qualification RGE Qualibois</h3>
            <p>L'Atrier Roannais certifié RGE Qualibois contribue au développement des installations à énergies renouvelables.</p>
            <p>Signe de qualité, ce label attribué par Qualit’EnR garantit l’expérience et le savoir-faire des installateurs Qualibois pendant 4 ans, avec des audits de contrôle.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default QualificationsSection