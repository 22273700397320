import * as React from 'react'
import { StaticQuery, Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const MarqueCard = ({ data }) => {
  return (
    <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
      <Link to={`/marques/${data.slug}`}>
        <GatsbyImage
          image={getImage(data.frontmatter.hero_image_16x9)}
          alt={data.frontmatter.hero_image_alt}
        />
      </Link>
    </div>
  )
}

const MarquesSection = () => (
    <StaticQuery
      query={graphql`
        query {
          marques: allMdx(
            filter: {
              fileAbsolutePath: { regex: "/marques/"},
              frontmatter: { accueil: { eq: true }, actif: { eq: true } }
            },
            sort: {fields: frontmatter___date, order: DESC}
          )
          {
            nodes {
              frontmatter {
                nom
                hero_image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                hero_image_16x9 {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                hero_image_alt
                hero_image_credit_link
                hero_image_credit_text
                accueil
                actif
                date(formatString: "MMMM D, YYYY")
              }
              id
              slug
            }
          }
        }
      `}
      render={data => (
        <section className="brands section-bg">
          <div className="container">
            <div className="row">
              {
                data.marques.nodes.map((marque,index) => (
                  <MarqueCard key={index} data={marque}/>
                ))
              }
            </div>
          </div>
        </section>
      )}
    />
)

export default MarquesSection