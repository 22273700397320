import * as React from 'react'
import CountUp from 'react-countup';

const CTASection = () => {
  return (
    <section className="counts section-bg">
      <div className="container">
        <div className="row counters">
          <div className="col-lg-3 col-6 text-center">
            <CountUp start={0} end={1800} delay={0} duration={3}>
                {({ countUpRef,start }) => (
                  <span ref={countUpRef} />
                )}
            </CountUp>
            <p>clients satisfaits</p>
          </div>
          <div className="col-lg-3 col-6 text-center">
          <CountUp start={0} end={496} delay={0} duration={3}>
                {({ countUpRef,start }) => (
                  <span ref={countUpRef} />
                )}
            </CountUp>
            <p>appareils référencés</p>
          </div>
          <div className="col-lg-3 col-6 text-center">
            <CountUp start={0} end={5} delay={0} duration={3}>
                {({ countUpRef,start }) => (
                  <span ref={countUpRef} />
                )}
            </CountUp>
            <p>employés à votre service</p>
          </div>
          <div className="col-lg-3 col-6 text-center">
            <CountUp start={0} end={17} delay={0} duration={3}>
                {({ countUpRef,start }) => (
                  <span ref={countUpRef} />
                )}
            </CountUp>
            <p>années d'expérience</p>
          </div>          
        </div>
      </div>
    </section>
  )
}

export default CTASection