import * as React from 'react'
import { Link } from 'gatsby'

const CTASection = () => {
  return (
    <section className="cta d-flex align-items-center justify-content-center">
      <div className="container position-relative">
        <h3>Vous avez une question? </h3>
        <p>Nous sommes à votre disposition.</p>
        <Link to={`/contact`} className="cta-btn">
          Contactez Nous
        </Link>
      </div>
    </section>
  )
}

export default CTASection